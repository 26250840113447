/*
 * @Author: tianpeixin 18617269361@163.com
 * @Date: 2024-01-02 10:56:09
 * @LastEditors: tianpeixin 18617269361@163.com
 * @LastEditTime: 2024-04-19 16:11:02
 * @FilePath: \h5-free\src\api\request.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// 全局安装axios并引用
import axios from "axios";
// 封装axios用于发送请求
// request 相当于 Axios 的实例对象
const request = axios.create({
  baseURL: "https://operate.bei-min.cn/", // 设置基地址，
  // baseURL: "http://192.168.1.8:8989",
  // baseURL: "/api",
  timeout: 5000, // 请求超时：当5s没有响应就会结束请求
});

// 添加请求拦截器
request.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
request.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    return response.data;
  },
  function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

// 暴露对象
export default request;
