<template>
  <div id="home">
    <div class="main">
      <!-- <div class="bgImg">

      </div> -->
      <!-- 顶部图片 -->
      <!-- <div class="banner mb">
        <img src="../static/image/banner.png" alt="" />
      </div> -->
      <!-- 搜索框 -->
      <div class="search flexBetween mb">
        <input
          type="number"
          placeholder="请输入1-4位您喜欢的数字"
          maxlength="4"
          v-model="pageParams.telstr"
        />
        <img
          src="../static/image/search-icon.png"
          alt=""
          class="imgs"
          @click="searchBtn()"
        />
      </div>
      <!-- 智能推荐 -->
      <div class="recommend mb flexBetween">
        <div>
          <div class="title">智能推荐靓号</div>
          <div class="recommendNum" v-if="recommendNum">
            <span> {{ recommendNum.substr(0, 3) }}-</span>
            <span>{{ recommendNum.substr(3, 4) }}-</span>
            <span> {{ recommendNum.substr(7, 11) }}</span>

            <!-- {{ recommendNum }} -->
            <div class="hotChange" @click="recommendChange()">
              换一个
              <!-- <img src="../static/image/changeIt-bg.png" alt="" /> -->
            </div>
          </div>
        </div>
        <div class="claimNow flexCenter" @click="recommendReceive()">
          立即领取
        </div>
      </div>
      <!-- 热门搜索 -->
      <div class="hotSearch mb">
        <div class="flex">
          <span class="hotSpan">热门搜索：</span>
          <div class="over">
            <div
              class="searchNum flexCenter"
              v-for="(v, i) in hotSearchNum"
              :key="i + v"
              @click="activeFun(v, i)"
              :class="{ active: activeIndex == i }"
            >
              {{ v }}
            </div>
          </div>
        </div>
        <div class="hotLocation flex" @click="selectCity()">
          {{ belongCity }}
          <img src="../static/image/below-icon.png" alt="" class="imgs" />
        </div>
      </div>
      <!-- 号码列表 -->
      <div class="numMain flexBetween">
        <div
          class="numItem mb"
          v-for="(v, i) in numList"
          :key="v + i"
          @click="giveBtn(v.hmmx)"
        >
          <img
            v-show="v.yxs == '电信网络'"
            src="../static/image/telecom.png"
            alt=""
          />
          <img
            v-show="v.yxs == '移动网络'"
            src="../static/image/mobile.png"
            alt=""
          />
          <img
            v-show="v.yxs == '联通网络'"
            src="../static/image/unicom.png"
            alt=""
          />
          <div class="topNum" v-show="v.hmmx">
            <!-- <span v-show="v.hmmx">{{ v.hmmx.substr(0, 3) }}-</span> -->

            <span>{{ v.hmmx.substr(0, 3) }}-</span>
            <span>{{ v.hmmx.substr(3, 4) }}-</span>
            <span class="red">{{ v.hmmx.substr(7, 11) }}</span>
          </div>
          <div class="flexBetween">
            <div class="city">
              <span>{{ v.gscs }}</span>
              <span v-show="v.yxs">{{ v.yxs.substr(0, 2) }}</span>
            </div>
            <div class="giveBtn">热销靓号 免费赠送</div>
          </div>
        </div>
      </div>
      <div class="changeBatch flexCenter" @click="changNum()" ref="changeBtn">
        换一批 <img src="../static/image/change-icon.png" alt="" class="imgs" />
      </div>
      <div
        class="receiveBtn flexCenter"
        @click="claimNow()"
        ref="receiveBtn"
        v-show="isFixed"
      >
        立即领取 0元包邮
      </div>
    </div>
    <div class="detailImg" ref="detail">
      <img
        v-show="this.pageType == 0"
        src="../static/image/detailImg.png"
        alt=""
      />
      <img
        v-show="this.pageType == 1"
        src="../static/image/detailImg2.png"
        alt=""
      />
    </div>
    <!-- 选择归属地 -->
    <van-popup
      v-model="belongingPlaceShow"
      position="bottom"
      :style="{ height: '35%' }"
    >
      <van-area
        title="选择所在地区"
        :area-list="areaList"
        :columns-num="2"
        @confirm="belongingPlaceConfirm"
        @cancel="belongingPlaceCancel"
    /></van-popup>
    <!-- <img alt="Vue logo" src="../assets/logo.png" /> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <!-- <van-button type="info">信息按钮</van-button> -->
    <div
      class="receiveBtn flexCenter fixed"
      @click="claimNow()"
      v-show="!isFixed"
      ref="receiveBtn2"
    >
      立即领取 0元包邮
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import { areaList } from "@vant/area-data"; //引入vant地区数据
import { phoneList, phoneRecommend } from "@/api/index.js";
export default {
  name: "HomeView",
  components: {
    HelloWorld,
  },
  data() {
    return {
      recommendNum: "", //推荐号码
      hotSearchNum: ["ABC", "AABB", "AAAB", "AA", "ABBA"], //号码类型aaa abcabc aabbcc abcd aa abba
      numList: [], //号码列表
      belongCity: "郑州市", //号码归属地
      areaList,
      belongingPlaceShow: false,
      activeIndex: null, //默认为0(如不需要默认可设为null)，，点击时将索引赋值给active，从而实现点击选中效果
      //搜索参数
      pageParams: {
        pagenum: 1,
        pageSize: 18,
        telstr: "",
        dqaddress: "",
        hmlx: "",
        yysname: "",
      },
      pagenum: 1,
      isFixed: true,
      pageType: 0, //链接参数
    };
  },
  created() {
    let data = {
      hmlx: "ABC",
      pageSize: 1,
      pagenum: this.pagenum,
    };
    phoneRecommend(data).then((res) => {
      // console.log(res);
      this.recommendNum = res.data.list.list[0].hmmx;
    });
    // 号码列表
    this.pageParams.pagenum = 0;
    this.pageParams.pagenum++;

    this.phoneLists(this.pageParams);
    // 获取路径
    let url = location.search;
    this.querystring(url);
    let urlParams = this.querystring(url);
    // console.log(urlParams);
    // let clickid = urlParams.clickid;
    let clickid;
    let aid = urlParams.aid;
    let terrace = urlParams.terrace;
    if (urlParams.yysname) {
      let yysname = urlParams.yysname.toString(); // 1--- 重庆联通  2----温州联通
      if (yysname == "温州联通") {
        localStorage.setItem("yysname", yysname);
        // 智能推荐 温州
        let data = {
          hmlx: "AABB",
          pageSize: 1,
          pagenum: this.pagenum,
          yysname: yysname,
        };
        phoneRecommend(data).then((res) => {
          this.recommendNum = res.data.list.list[0].hmmx;
        });
        // 号码列表
        this.pageParams.pagenum = 0;
        this.pageParams.pagenum++;
        this.pageParams.yysname = yysname;
        this.phoneLists(this.pageParams);
      } else {
        // 重庆
        localStorage.setItem("yysname", "");
        let data = {
          hmlx: "ABC",
          pageSize: 1,
          pagenum: this.pagenum,
        };
        phoneRecommend(data).then((res) => {
          // console.log(res);
          this.recommendNum = res.data.list.list[0].hmmx;
        });
        // 号码列表
        this.pageParams.pagenum = 0;
        this.pageParams.pagenum++;

        this.phoneLists(this.pageParams);
      }
    }

    if (urlParams.pageType) {
      //根据链接参数展示不同详情图
      this.pageType = urlParams.pageType;
    }
    // let callback = urlParams.callback;
    if (terrace == 2) {
      clickid = urlParams.callback;
    } else if (terrace == 3) {
      if (urlParams.qz_gdt) {
        clickid = urlParams.qz_gdt;
      } else {
        clickid = urlParams.gdt_vid;
      }
    } else {
      clickid = urlParams.clickid;
    }
    let planId = urlParams.planId;
    if (clickid) {
      localStorage.setItem("clickid", clickid);
    }
    if (aid) {
      localStorage.setItem("aid", aid);
    }
    if (terrace) {
      localStorage.setItem("terrace", terrace);
    }
    if (planId) {
      localStorage.setItem("planId", planId);
    }
    // localStorage.setItem("callback", callback);

    // console.log(urlParams);
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  // 离开这个页面销毁滚动条事件，不然会给每一个页面都触发
  destroyed() {
    let that = this;
    window.removeEventListener("scroll", that.handleScroll);
  },
  methods: {
    // 吸顶 定义处理滚动事件的函数 handleScroll，并在该函数中计算当前滚动位置与吸顶元素的位置关系
    handleScroll() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      const testEle = this.$refs.receiveBtn;
      const testEle2 = this.$refs.receiveBtn2; //detail
      const detail = this.$refs.detail; //detail
      const changeBtn = this.$refs.changeBtn; //detail
      // console.log(
      //   "scrollTop",
      //   scrollTop,
      //   testEle.offsetTop,
      //   testEle2.offsetTop,
      //   detail.offsetTop
      // );
      // console.log("testEle", testEle.offsetTop);
      // console.log("testEle2", testEle2.offsetTop);
      // console.log("detail", detail.offsetTop);
      // console.log("changeBtn", changeBtn.offsetTop);
      if (scrollTop > testEle.offsetTop) {
        this.isFixed = false;
        if (scrollTop < 450) {
          this.isFixed = true;
        }
      }

      // if (detail.offsetTop > testEle2.offsetTop) {
      //   console.log(666666666);
      //   this.isFixed = true;
      // }
    },
    // 号码列表
    async phoneLists(data) {
      let res = await phoneList(data);
      if (res.code == 200) {
        this.numList = res.data.list.list;
      }
      // console.log(this.numList);
    },
    // 号码类型 选中高亮
    activeFun(v, i) {
      this.activeIndex = i;
      this.pageParams.hmlx = v;
      this.pageParams.pagenum = 1;
      this.numList = [];
      this.phoneLists(this.pageParams);
      // console.log(v, i);
    },
    // 搜索按钮
    searchBtn() {
      this.pageParams.pagenum = 0;
      this.pageParams.pagenum++;
      this.numList = [];
      this.phoneLists(this.pageParams);
    },
    // 智能推荐换一个
    recommendChange() {
      this.pagenum++;
      let data = {
        hmlx: "ABC",
        pageSize: 1,
        pagenum: this.pagenum,
      };
      phoneRecommend(data).then((res) => {
        // console.log(res);
        this.recommendNum = res.data.list.list[0].hmmx;
      });
    },
    // 智能推荐立即领取
    recommendReceive() {
      this.$router.push("/detail?hmmx=" + this.recommendNum);
    },
    // 换一批
    changNum() {
      // this.pageParams.pagenum=0
      this.pageParams.pagenum++;
      this.numList = [];
      this.phoneLists(this.pageParams);
    },
    giveBtn(hmmx) {
      this.$router.push("/detail?hmmx=" + hmmx);
    },
    // 立即抢购
    claimNow() {
      this.$router.push("/detail?hmmx=" + this.recommendNum);
    },
    // 选择城市
    selectCity() {
      this.belongingPlaceShow = true;
    },
    belongingPlaceConfirm(e) {
      this.pageParams.pagenum = 0;
      this.belongCity = e[1].name;
      this.pageParams.dqaddress = e[1].name;
      this.pageParams.pagenum++;
      this.numList = [];
      this.phoneLists(this.pageParams);
      this.belongingPlaceShow = false;
    },
    belongingPlaceCancel() {
      this.belongingPlaceShow = false;
    },
    //获取路径中的参数
    // 使用字符串分割和reduce方法获取路径参数值
    querystring(queryStr) {
      const [, query] = queryStr.split("?");
      if (query) {
        return query.split("&").reduce((pre, cur) => {
          const [key, val] = cur.split("=");
          if (pre[key]) {
            pre[key] = [...pre[key], decodeURIComponent(val)];
          } else {
            pre[key] = [decodeURIComponent(val)];
          }
          return pre;
        }, {});
      }
      return {};
    },
  },
};
</script>
<style scoped>
#home {
  width: 375px;
  /* background: #f6f6f6;
  padding: 10px 16px;
  padding-bottom: 0; */
}
.main {
  padding: 10px 16px;
  padding-bottom: 24px;
  width: 375px;
  /* height: 1124px; */
  /* background: linear-gradient(180deg, #e5432c 0%, #f6f6f6 100%); */
  background-image: url("../static/image/indexImg.png");
  background-size: 100%;
  background-repeat: no-repeat;
  padding-top: 1px;
  /* background-position: 0 -44px; */
}
.banner {
  width: 343px;
  height: 180px;
  background: #ffe7e0;
  border-radius: 8px 8px 8px 8px;
}
.banner img {
  width: 100%;
  height: 100%;
}
/* 下外边距12px */
.mb {
  margin-bottom: 12px;
}
.flex {
  display: flex;
  align-items: center;
}
.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.imgs {
  width: 24px;
  height: 24px;
}
.search {
  width: 343px;
  height: 40px;
  background: #ffffff;
  border-radius: 20px 20px 20px 20px;
  padding: 0 12px;
  margin-top: 248px;
}
.search input {
  width: 100%;
  outline: none;
  border: none;
  height: 18px;
  font-size: 14px;
  font-family: "PingFang SC-Medium";
  font-weight: 500;
  color: #999999;
  line-height: 18px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.recommend {
  width: 343px;
  height: 84px;
  background: linear-gradient(180deg, #ffe7e0 0%, #fff5f2 100%);
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 1px solid #ffffff;
  padding: 12px 16px;
}
.title {
  height: 18px;
  font-size: 16px;
  font-family: "PingFang SC-Bold";
  /* font-weight: bold; */
  color: #510a00;
  line-height: 18px;
  margin-bottom: 16px;
}
.recommendNum {
  height: 18px;
  font-size: 23px;
  font-family: "PingFang SC-Semibold";
  font-weight: 600;
  color: #e5432c;
  line-height: 18px;
  position: relative;
  display: flex;
}
.hotChange {
  width: 60px;
  height: 22px;
  font-size: 12px;
  font-family: "PingFang SC-Medium";
  font-weight: 500;
  color: #e5432c;
  background-image: url("../static/image/changeIt-bg.png");
  background-size: 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: -34px;
  right: 18px;
  text-align: center;
}
.claimNow {
  width: 86px;
  height: 26px;
  background: linear-gradient(90deg, #e5432c 0%, #ea5b31 100%);
  border-radius: 13px 13px 13px 13px;
  font-size: 14px;
  font-family: "PingFang SC-Bold";
  color: #fff;
  /* font-weight: bold; */
}
.hotSearch {
  width: 343px;
  height: 24px;
  /* overflow-x: auto; */
  position: relative;
}
.hotSearch::-webkit-scrollbar {
  display: none; /* 隐藏滚动条 */
}
.searchNum {
  /* width: 42px; */
  height: 18px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  font-size: 12px;
  font-family: "PingFang SC-Medium";
  margin-right: 7px;
  padding: 0 5px;
}
.hotSpan {
  width: 70px;
  height: 18px;
  font-size: 14px;
  font-family: "PingFang SC-Medium";
  font-weight: 500;
  color: #ffffff;
  line-height: 18px;
  position: absolute;
  left: 0;
}
.hotLocation {
  display: none;
  /* width: 76px; */
  height: 24px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  font-size: 14px;
  font-family: "PingFang SC-Medium";
  line-height: 24px;
  padding-left: 8px;
}
.numItem {
  width: 167px;
  height: 76px;
  background: #ffffff;
  border-radius: 5px 5px 5px 5px;
  padding: 13px 8px;
  padding-right: 0;
  padding-bottom: 0;
  position: relative;
}
.numItem img {
  width: 44px;
  height: 32px;
  position: absolute;
  top: 0;
  right: 0;
}
.numMain {
  flex-wrap: wrap;
}
.topNum {
  height: 25px;
  font-size: 18px;
  font-weight: 600;
  color: #333333;
  font-family: "PingFang SC-Semibold";
  margin-bottom: 7px;
}
.red {
  color: #c9171c;
}
.city {
  font-size: 11px;
  font-family: "PingFang SC-Medium";
  font-weight: 500;
  color: #999999;
}
.giveBtn {
  width: 95px;
  height: 22px;
  background: linear-gradient(90deg, #e5432c 0%, #ea5b31 100%);
  border-radius: 12px 12px 12px 12px;
  font-family: "PingFang SC-Bold";
  /* font-weight: bold; */
  color: #fff;
  font-size: 10px;
  line-height: 22px;
  text-align: center;
  margin-right: 8px;
  display: flex;
  justify-content: space-around;
  margin-right: 8px;
}
.receiveBtn {
  width: 343px;
  height: 40px;
  background: linear-gradient(90deg, #e5432c 0%, #ea5b31 100%);
  border-radius: 20px 20px 20px 20px;
  /* font-weight: bold; */
  color: #ffffff;
  font-size: 16px;
  font-family: "PingFang SC-Bold";
  /* position: sticky;
  bottom: 0;
  left: 16px; */
}
.fixed {
  position: sticky;
  bottom: 20px;
  left: 16px;
}
.changeBatch {
  height: 24px;
  line-height: 24px;
  /* font-weight: bold; */
  color: #e5432c;
  font-size: 14px;
  font-family: "PingFang SC-Bold";
  margin-bottom: 16px;
  text-align: center;
}
.detailImg {
  padding: 0 16px;
  margin-bottom: 32px;
}
.detailImg img {
  width: 100%;
}
.active {
  /* background: rgba(229, 67, 44, 0.1); */
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  color: #e5432c;
  /* border: 1px solid #e5432c; */
}
.over {
  margin-left: 70px;
  display: flex;
  overflow-x: auto;
}
.over::-webkit-scrollbar {
  display: none; /* 隐藏滚动条 */
}
</style>
