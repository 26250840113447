import request from "@/api/request";

// 列表查询
export function phoneList(query) {
  return request({
    url: "/api/phone/getSearch",
    method: "get",
    params: query,
  });
}
// 智能推荐
export function phoneRecommend(query) {
  return request({
    url: "/api/phone/getSearch",
    method: "get",
    params: query,
  });
}
// /api/phone/saveOrder  下单
export function saveOrder(data) {
  return request({
    url: "/api/phone/saveOrder",
    method: "post",
    data: data,
  });
}

// /api/phone/selectBuyName
export function selectBuyName() {
  return request({
    url: "/api/phone/selectBuyName",
    method: "get",
  });
}
//getPhoneDetail
export function getPhoneDetail(id) {
  return request({
    url: "/api/phone/getPhoneDetail?phone=" + id,
    method: "get",
  });
}
//officialaccounturl 关注公众号
export function officialAccount() {
  return request({
    url: "/api/phone/officialaccounturl",
    method: "get",
  });
}
// 获取地理位置
export function getAddress() {
  return request({
    url: "https://h5.bei-min.cn/api/telweb/getAddress",
  });
}
