import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "@/static/css/reset.css";
import "@/static/css/font.css";

import "vant/lib/index.css";
import "lib-flexible/flexible";
import axios from "axios";

import {
  Button,
  Icon,
  Picker,
  Popup,
  Area,
  Field,
  Swipe,
  SwipeItem,
  Toast,
  Dialog,
  NumberKeyboard,
} from "vant";

Vue.use(NumberKeyboard);
Vue.use(Dialog);
Vue.use(Toast);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Field);
Vue.use(Area);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Icon);
Vue.use(Button);
Vue.prototype.$axios = axios;
Vue.config.productionTip = false;
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
